<template>
  <div class="app-container">
    <van-nav-bar
        :fixed="true"
        :placeholder="true"
        title="联系人详情"
        left-text="返回"
        right-text="修改"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight('addressInfo')"
    />
    <div class="BulkCrmsCusAddressEditLs">
      <el-row>
        <el-col :span="24">
          <el-form ref="addressInfo" :model="addressInfo" :rules="rules" label-width="80px" class="demo-ruleForm">
            <van-row>
              <van-field
                  readonly
                  clickable
                  label="省市："
                  :value="addressInfo.selPrCyValue"
                  :rules="rules.prCyValue"
                  placeholder="选择省市"
                  @click="addressInfo.showPrCyPicker = true"
              />
              <van-popup v-model="addressInfo.showPrCyPicker" round position="bottom">
                <van-tree-select
                    :items="addressInfo.prCyItems"
                    :active-id.sync="addressInfo.prCyActiveId"
                    :main-active-index.sync="addressInfo.prCyActiveIndex"
                    @click-item="onClickItem"
                    @click-nav="onClickNav"
                />
              </van-popup>
            </van-row>
            <el-form-item label="详细地址" prop="detail">
              <el-input v-model="addressInfo.detail" placeholder="请输入详细地址"/>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import BMapAddressSelect from "@/components/BMapAddressSelect/index";

export default {
  name: "BulkCrmsCusAddressEditLs",
  components: {BMapAddressSelect},
  data() {
    return {
      // 位置信息
      addressInfo: {
        addressId: '',
        detail: '',
        cusId: '',
        province: '',
        city: '',
        selPrCyValue: '',
        showPrCyPicker: false,
        prCyItems: [],
        prCyActiveId: '',
        prCyActiveIndex: -1,
      },
      rules: {
        detail: [
          {required: true, message: '请填写详细地址', trigger: 'blur'},
        ],
        prCyValue: [
          {required: true, message: '请选择省市！'},
        ]
      },
      searchAddresKeywords: "",
      openMap: false,
    };
  },
  mounted() {
    this.$ajax.post('/api/ajax/cusAddress/getBulkCrmsLsCusAddressList.json',
        {id: this.$route.params.name, cusId: ''}).then(res => {
      if (res.status == 200) {
        this.addressInfo.addressId = res.data[0].id
        this.addressInfo.detail = res.data[0].detail
        this.addressInfo.cusId = res.data[0].cusId
        this.addressInfo.province = res.data[0].province
        this.addressInfo.city = res.data[0].city
      }
    }).catch(err => {
      console.log(err)
      this.$message.error('初始化失败！');
    })
    //省市下拉数据获取
    this.$ajax.post('/api/ajax/cusAddress/getPrCyItemsInfo.json', {cusId: 'ti01'}).then(res => {
      if (res.data != null) {
        this.addressInfo.prCyItems = res.data.prCyItems
        if (this.addressInfo.prCyItems && this.addressInfo.prCyItems.length > 0) {
          //默认选择
          this.addressInfo.prCyActiveIndex = 0
          this.addressInfo.prCyActiveId = 0
          let prCyActiveIndexText = this.addressInfo.province
          let prCyActiveIdText = ''
          this.addressInfo.prCyItems.forEach((value, index) => {
            if (value.text == prCyActiveIndexText) {
              this.addressInfo.prCyActiveIndex = index
              this.addressInfo.prCyActiveIndexId = value.text
              value.children.forEach((v, i) => {
                if (i == 0) {
                  this.addressInfo.prCyActiveId = v.id
                  prCyActiveIdText = v.text
                  return false
                }
                if (v.text == this.addressInfo.city) {
                  this.addressInfo.prCyActiveId = v.id
                  prCyActiveIdText = v.text
                }
              })
              return false;
            }
          })
          if (this.addressInfo.prCyActiveIndex > -1) {
            this.addressInfo.selPrCyValue = prCyActiveIndexText + " / " + prCyActiveIdText
          }
        }
      }
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onClickLeft() {
      //返回上一级页面
      this.$router.push({path: '/BulkCrmsCusAddressListLs/' + sessionStorage.getItem('cusId')})
    },
    onClickRight(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$ajax.post('/api/ajax/cusAddress/updateBulkCrmsLsCusAddress.json',
              {
                detail: this.addressInfo.detail,
                province: this.addressInfo.selPrCyValue.split('/')[0].replace(/\s*/g, ''),
                city: this.addressInfo.selPrCyValue.split('/')[1].replace(/\s*/g, ''),
                id: this.addressInfo.addressId,
              }).then(res => {
            if (res.status == 200) {
              this.$message({
                showClose: true,
                message: '修改成功',
                type: 'success'
              });
              setTimeout(() => {
                this.$router.replace({path: '/BulkCrmsCusAddressListLs/' + this.addressInfo.cusId})
              }, 200);
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('修改失败');
          })
        } else {
          console.log('未通过校验!!');
          return false;
        }
      });

    },
    onClickItem(data) {
      console.log(data, this.addressInfo.prCyActiveId, this.addressInfo.prCyActiveIndex);
      this.prCyActiveId = data.id

      let prCyActiveIndexText = ""
      let prCyActiveIdText = ""
      this.addressInfo.prCyItems.forEach((value, index) => {
        if (index == this.addressInfo.prCyActiveIndex) {
          this.addressInfo.prCyActiveIndexId = value.text
          prCyActiveIndexText = value.text

          value.children.forEach((v) => {
            if (v.id == this.addressInfo.prCyActiveId) {
              prCyActiveIdText = v.text
              return false
            }
          })
          return false;
        }
      })

      if (prCyActiveIndexText.length > 0 && prCyActiveIdText.length > 0) {
        this.addressInfo.selPrCyValue = prCyActiveIndexText + " / " + prCyActiveIdText
      }

      this.addressInfo.showPrCyPicker = false
    },
    onClickNav(index) {
      console.log(index);
      this.addressInfo.prCyActiveIndex = index
    },
  },
}
</script>

<style scoped>
.app-container {
  background: white;
}

.FirstAddressContactAdd {
  margin: 15px 25px 15px 15px;
  background: white;
}

.detail {
  padding: 5px;
}

.van-button {
  width: 220px;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  font-family: 新宋体;
}

</style>
